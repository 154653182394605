@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background: #111111;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:  #111111;
}

::-webkit-scrollbar {
    width: 5px;
    height: 0px;
}

::-webkit-scrollbar-track {
    background: #111111;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(83, 83, 83);
    border-radius: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.custom-scrollbar::-webkit-scrollbar {
  width: 4px; 
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #111111; 
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(83, 83, 83);
  border-radius: 20px; 
  height: 10px;
}

.custom-scrollbar-transparent::-webkit-scrollbar {
  width: 4px; 
}

.custom-scrollbar-transparent::-webkit-scrollbar-track {
  background: transparent; 
}

.custom-scrollbar-transparent::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px; 
}


.scrollbar-green::-webkit-scrollbar {
  width: 5px; 
  height: 10px;
}

.scrollbar-green::-webkit-scrollbar-thumb {
  background-color: #008944;
  border-radius: 10px;
}

.scrollbar-green::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-placeholder::placeholder {
  color:#6B6B6B;
}